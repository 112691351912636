import Carousel from 'react-bootstrap/Carousel';
import './Carousel.sass';

function SimpleCarousel() {
  return (
    <Carousel controls={false} indicators={false}>
      <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src="https://wallpapers.com/images/featured/qbc6mlnwowjbszld.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src="https://img.theculturetrip.com/wp-content/uploads/2021/03/k4m7ab-e1617152502291.jpg"
          alt="Second slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src="https://wallpapercave.com/wp/wp11939979.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
    </Carousel>
  );
}

export default SimpleCarousel;